import { defineStore } from 'pinia';
import { IAddress, IInterimDB, ObjectID } from '@/types';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { formattedAddress, isValidPhone } from '@/helpers/commons';
import i18n from '@/i18n';
import { ESearchWorkerModes } from '@/data';
import store from '.';

export interface ICommonWorker extends Pick<
    IInterimDB,
    '_id' | 'firstname' | 'lastname' | 'nir' | 'email' | 'phone' | 'birthPlace'
> {
    address: IAddress | string,
    birthDate: string | number,
    evoliaId: number | string,
    databaseOrigin: ESearchWorkerModes,
    agencies: { [agencyId: string]: {_id: ObjectID, deleted: boolean; inactive: boolean; candidateStatus?: string;} }[],
    candidateId?: string,
    workerId?: string,
}

export const useWorkerAddIdentificateStore = defineStore('workerAddIdentificate',  {
    state: () => ({
        loading: false as boolean,
        mode: "" as "interim" | "candidate",
        findWorkerForm: {
            firstname: {value: '', isValid: true},
            lastname: {value: '', isValid: true},
            birthDate: {value: '', isValid: true},
            nir: {value: '', isValid: true},
            email: {value: '', isValid: true},
            phone: {value: '', isValid: true},
        },
        duplicatedWorkers: [] as ICommonWorker[],
        cvParsing: {} as any,
        cv: "" as string,
    }),



    actions:
    {
        getSummuryWorker(index: number): ICommonWorker {
            return {
                _id: this.duplicatedWorkers[index]._id || '',
                firstname: this.duplicatedWorkers[index]?.firstname || '',
                lastname: this.duplicatedWorkers[index]?.lastname || '',
                evoliaId: this.duplicatedWorkers[index]?.evoliaId?.toString() || '',
                nir: this.duplicatedWorkers[index]?.nir || '' ,
                email: this.duplicatedWorkers[index]?.email || '' ,
                phone: this.duplicatedWorkers[index]?.phone ? parsePhoneNumber(this.duplicatedWorkers[index]?.phone, 'FR').formatNational() : '',
                birthPlace: this.duplicatedWorkers[index]?.birthPlace || '' ,
                birthDate: this.duplicatedWorkers[index].birthDate!== 0 ? moment(this.duplicatedWorkers[index].birthDate).format(i18n.t('date.format') as string) : '',
                address: formattedAddress(this.duplicatedWorkers[index]?.address || {}),
                databaseOrigin: this.duplicatedWorkers[index].databaseOrigin || '',
                agencies: this.duplicatedWorkers[index].agencies || [],
                ...(this?.duplicatedWorkers?.[index]?.workerId ? {workerId: this.duplicatedWorkers[index].workerId} : {}),
                ...(this?.duplicatedWorkers?.[index]?.candidateId ? {workerId: this.duplicatedWorkers[index].candidateId} : {}),
            }
        },
        async fetchDuplicatedWorkers() {
            const req = {
                mode: {
                    [ESearchWorkerModes.INTERIM]: {
                        "isActiveWorker": false,
                        "scope": "group",
                        "filterDeleted": true,
                    },
                    [ESearchWorkerModes.CANDIDATE]: {
                        "isActiveWorker": false,
                        "scope": "group",
                        "filterDeleted": true,
                        "filterToIdentificate": true,
                    },
                    [ESearchWorkerModes.EVOLIA]: {
                        "isActiveWorker": false,
                        "scope": "group",
                    },
                },
                searchFields: {
                    firstname: this.findWorkerForm.firstname.value,
                    lastname: this.findWorkerForm.lastname.value,
                    optionalFields: {}
                },
                limitScore: 0.80
            }

            if(this.mode === "interim") {
                req.searchFields.optionalFields = {
                    birthDate: {
                        value: moment(this.findWorkerForm.birthDate.value, 'DD/MM/YYYY').valueOf(),
                        isFilter: false,
                        weight: 4,
                        isMandatory: true,
                    },
                    nir: {
                        value: this.findWorkerForm.nir.value,
                        isFilter: false,
                        weight: 2,
                        isMandatory: true,
                    }
                }
            }
            if(this.mode === "candidate") {
                req.searchFields.optionalFields = {
                    birthDate: {
                        value: moment(this.findWorkerForm.birthDate.value, 'DD/MM/YYYY').valueOf(),
                        isFilter: false,
                        weight: 1,
                        isMandatory: false,
                    },
                    email: {
                        value: this.findWorkerForm.email.value,
                        isFilter: false,
                        weight: 1,
                        isMandatory: false,
                    },
                    phone: {
                        value: this.findWorkerForm.phone.value,
                        isFilter: false,
                        weight: 1,
                        isMandatory: true,
                    }
                }
            }

            this.loading = true;
            this.duplicatedWorkers = await store.dispatch('actions/searchWorker', req);
            this.loading = false;
        },
        async fetchCvParsing(cv: string) {
            this.loading = true;
            this.cv = cv;
            this.cvParsing = await store.dispatch('actions/analyseCandidate', {data: {cv: cv}});

            this.findWorkerForm.firstname.value = this.cvParsing.parsed.firstname?.value || '';
            this.findWorkerForm.lastname.value = this.cvParsing.parsed.lastname?.value || '';
            this.findWorkerForm.birthDate.value = moment(this.cvParsing.parsed.birthDate.value).format('DD/MM/YYYY') || '';
            this.findWorkerForm.email.value = this.cvParsing.parsed.email?.value || '';
            this.findWorkerForm.phone.value = this.cvParsing.parsed.phone?.value ? parsePhoneNumber(this.cvParsing.parsed.phone?.value.toString(), 'FR').formatNational() : '';

            this.loading = false;
        },
        async actionShareWorker(params: {databaseOrigin: 'interim' | 'candidate' | 'evolia', importTo: 'interim' | 'candidate', workerId: string|ObjectID}) {
            return await store.dispatch('actions/shareWorker', params);
        }
    }

});
