var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"InterimInformations"},[_c('row',[_c('column',{attrs:{"d":"4"}},[(
                    _vm.interim.vehicle ||
                    _vm.isNightAvailable() ||
                    _vm.isWeekEndAvailable()
                )?_c('div',{staticClass:"disponibilities"},[(_vm.interim.vehicle)?_c('div',{class:`disponibility ${
                        _vm.interim.vehicle ? 'active' : ''
                    }`},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.hasVehicle"))+" ")])]):_vm._e(),(_vm.isNightAvailable())?_c('div',{class:`disponibility ${
                        _vm.isNightAvailable() ? 'active' : ''
                    }`},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.hasNightAvailable"))+" ")])]):_vm._e(),(_vm.isWeekEndAvailable())?_c('div',{class:`disponibility ${
                        _vm.isWeekEndAvailable() ? 'active' : ''
                    }`},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.hasWeekendAvailable"))+" ")])]):_vm._e()]):_vm._e(),(_vm.interim?.lastAgencyContact)?_c('TextInput',{attrs:{"readonly":"","label":_vm.$t('page.interims.lastContacDate'),"value":_vm.$t('page.interims.lastContactDetail', {
                        date: _vm.lastContactDate.date,
                        collaborator: _vm.lastContactDate.collaborator,
                    })},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'nationality')}}}):_vm._e(),(_vm.interim.nationalityCode)?_c('TextInput',{attrs:{"newValue":_vm.changedNationality,"label":_vm.$t('form.nationality'),"value":_vm.nationality,"disabled":true,"rightIcon":_vm.getAskedChangeIcon('nationalityCode'),"helpText":_vm.getHelpText('nationalityCode')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'nationality')}}}):_vm._e(),(
                    _vm.interim.birthDate ||
                    (_vm.interim.askedChanges && _vm.interim.askedChanges.birthDate)
                )?_c('TextInput',{attrs:{"newValue":_vm.changedBirthDate,"label":_vm.$t('form.birthDate'),"value":_vm.birthDate,"disabled":true,"rightIcon":_vm.getAskedChangeIcon('birthDate'),"helpText":_vm.getHelpText('birthDate')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'birthDate')}}}):_vm._e(),(
                    _vm.interim.birthPlace ||
                    (_vm.interim.askedChanges &&
                        _vm.interim.askedChanges.birthPlace)
                )?_c('TextInput',{attrs:{"newValue":_vm.changedBirthPlace,"label":_vm.$t('form.birthPlace'),"value":_vm.interim.birthPlace,"disabled":true,"rightIcon":_vm.getAskedChangeIcon('birthPlace'),"helpText":_vm.getHelpText('birthPlace')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'birthPlace')}}}):_vm._e(),(_vm.interim.email)?_c('TextInput',{attrs:{"hasChanged":_vm.hasChanges('email'),"newValue":_vm.hasChanges('email'),"label":_vm.$t('form.email'),"value":_vm.interim.email,"disabled":true,"rightIcon":_vm.getAskedChangeIcon('email'),"helpText":_vm.getHelpText('email')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'email')}}}):_vm._e(),(
                    _vm.interim.phone ||
                    (_vm.interim.askedChanges && _vm.interim.askedChanges.phone)
                )?_c('TextInput',{attrs:{"newValue":_vm.changedPhone,"label":_vm.$t('form.phone'),"value":_vm.interim.phone,"disabled":true,"rightIcon":_vm.getAskedChangeIcon('phone'),"helpText":_vm.getHelpText('phone')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'phone')}}}):_vm._e(),(_vm.interim.nir && _vm.interim.nirKey)?_c('TextInput',{attrs:{"value":_vm.nirStr,"label":'Numéro de sécurité sociale',"mode":'nir',"disabled":true}}):_vm._e(),_c('TextInput',{attrs:{"label":_vm.$t('page.interim.managerLabel'),"value":_vm.managerName,"disabled":true}}),_c('card',[(
                        (_vm.interim.jobs && _vm.interim.jobs.length) ||
                        (_vm.interim.askedChanges &&
                            _vm.interim.askedChanges.jobs.length)
                    )?_c('div',{staticClass:"jobs"},[_c('asked-field',{staticClass:"job-changes",attrs:{"hasChanged":_vm.jobChanges},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'jobs')}}},[_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("form.jobs")))]),(_vm.jobChanges)?_c('span',[_vm._v("Metiers actuelles")]):_vm._e(),_vm._l((_vm.interim.jobs),function(job,index){return _c('div',{key:index,staticClass:"job-content-interim"},[_c('div',{class:`top-job`},[_c('div',{staticClass:"jobname"},[_vm._v(" "+_vm._s(_vm.getJobOption(job.jobId).label)+" ")]),(
                                        job.degreeLevel &&
                                        job.degreeLevel !== 'nodegree'
                                    )?_c('div',{staticClass:"jobdegree"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.degreeLevel", { degreeLevel: _vm.getDegreeLevel( job.degreeLevel ), }))+" ")]):_vm._e(),(job.experience)?_c('div',{class:`jobxp ${job.experience}`},[_vm._v(" "+_vm._s(_vm.$t( "jobExperience." + job.experience ))+" ")]):_vm._e()])])}),(_vm.updatedJobs.length > 0)?[_c('div',{staticClass:"category-changes"},[_vm._v(" Metiers mis à jours ")]),_vm._l((_vm.updatedJobs),function(job,index){return _c('div',{key:job.jobId,staticClass:"job-content-interim with-changes"},[_c('div',{class:`top-job changes`},[_c('div',{staticClass:"jobname"},[_vm._v(" "+_vm._s(_vm.getJobOption(job.current.jobId) .label)+" ")]),(
                                            job.current.degreeLevel &&
                                            job.current.degreeLevel !==
                                                'nodegree'
                                        )?_c('div',{staticClass:"jobdegree"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.degreeLevel", { degreeLevel: _vm.getDegreeLevel( job.current.degreeLevel ), }))+" ")]):_vm._e(),(job.current.experience)?_c('div',{class:`jobxp ${job.current.experience}`},[_vm._v(" "+_vm._s(_vm.$t( "jobExperience." + job.current.experience ))+" ")]):_vm._e()]),(_vm.hasChanges('jobs')[index])?_c('div',{staticClass:"changed-job"},[_c('icon',{attrs:{"icon":"update"}}),_c('div',{staticClass:"jobname"},[_vm._v(" "+_vm._s(_vm.getJobOption(job.changes.jobId) .label)+" ")]),(
                                            job.changes.degreeLevel &&
                                            job.changes.degreeLevel !==
                                                'nodegree'
                                        )?_c('div',{staticClass:"jobdegree"},[_vm._v(" "+_vm._s(job.changes.degreeLevel)+" ")]):_vm._e(),(job.changes.experience)?_c('div',{class:`jobxp ${job.changes.experience}`},[_vm._v(" "+_vm._s(_vm.$t( "jobExperience." + job.changes.experience ))+" ")]):_vm._e()],1):_vm._e()])})]:_vm._e(),(_vm.addedJobs.length > 0)?[_c('div',{staticClass:"category-changes"},[_vm._v(" Metiers ajoutés ")]),_vm._l((_vm.addedJobs),function(job){return _c('div',{key:job.jobId,staticClass:"job-content-interim with-changes"},[_c('div',{class:`top-job changes new-job`},[_c('div',{staticClass:"jobname"},[_vm._v(" "+_vm._s(_vm.getJobOption(job.jobId).label)+" ")]),(
                                            job.degreeLevel &&
                                            job.degreeLevel !== 'nodegree'
                                        )?_c('div',{staticClass:"jobdegree"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.degreeLevel", { degreeLevel: _vm.getDegreeLevel( job.degreeLevel ), }))+" ")]):_vm._e(),(job.experience)?_c('div',{class:`jobxp ${job.experience}`},[_vm._v(" "+_vm._s(_vm.$t( "jobExperience." + job.experience ))+" ")]):_vm._e()]),_c('div',{staticClass:"changed-job"},[_c('icon',{attrs:{"icon":"update"}}),_c('div',{staticClass:"jobname"},[_vm._v(" Ajout de metier ")])],1)])})]:_vm._e(),(_vm.removedJobs.length > 0)?[_c('div',{staticClass:"category-changes"},[_vm._v(" Metiers supprimés ")]),_vm._l((_vm.removedJobs),function(job){return _c('div',{key:job.jobId,staticClass:"job-content-interim with-changes"},[_c('div',{class:`top-job changes new-job`},[_c('div',{staticClass:"jobname"},[_vm._v(" "+_vm._s(_vm.getJobOption(job.jobId).label)+" ")]),(
                                            job.degreeLevel &&
                                            job.degreeLevel !== 'nodegree'
                                        )?_c('div',{staticClass:"jobdegree"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.degreeLevel", { degreeLevel: _vm.getDegreeLevel( job.degreeLevel ), }))+" ")]):_vm._e(),(job.experience)?_c('div',{class:`jobxp ${job.experience}`},[_vm._v(" "+_vm._s(_vm.$t( "jobExperience." + job.experience ))+" ")]):_vm._e()]),_c('div',{staticClass:"changed-job"},[_c('icon',{attrs:{"icon":"update"}}),_c('div',{staticClass:"jobname"},[_vm._v("Supprimer")])],1)])})]:_vm._e()],2)],1):_vm._e()]),(_vm.hasMissions)?_c('div',{staticClass:"missions"},[(_vm.interim.missions.currentMissions.length)?[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.currentMissions"))+" ")]),_c('card',[_c('div',{staticClass:"tiles"},_vm._l((_vm.interim.missions
                                    .currentMissions),function(mission,index){return _c('mission-tile',{key:index,attrs:{"mission":mission}})}),1)])]:_vm._e(),(_vm.interim.missions.futureMissions.length)?[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.futurMissions"))+" ")]),_c('card',[_c('div',{staticClass:"tiles"},_vm._l((_vm.interim.missions
                                    .futureMissions),function(mission,index){return _c('mission-tile',{key:index,attrs:{"mission":mission}})}),1)])]:_vm._e(),(_vm.interim.missions.lastMissions.length)?[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.lastMissions"))+" ")]),_c('card',[_c('div',{staticClass:"tiles"},_vm._l((_vm.interim.missions
                                    .lastMissions),function(mission,index){return _c('mission-tile',{key:index,attrs:{"mission":mission}})}),1)])]:_vm._e()],2):_vm._e()],1),(_vm.regularAvailabilities || _vm.blacklist || _vm.inactivity)?_c('column',{attrs:{"d":"8"}},[(_vm.inactivity)?_c('div',{staticClass:"inactivity"},[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.inactivity"))+" ")]),_c('card',[_c('div',{staticClass:"reasonInactivity",domProps:{"innerHTML":_vm._s(_vm.inactivityReason)}})])],1):_vm._e(),(_vm.regularAvailabilities)?_c('div',{staticClass:"regular"},[_c('week-unavailabilities',{attrs:{"legend":"","readonly":"","directValue":""},model:{value:(_vm.interim.regularAvailabilities),callback:function ($$v) {_vm.$set(_vm.interim, "regularAvailabilities", $$v)},expression:"interim.regularAvailabilities"}})],1):_vm._e(),(_vm.interimAddress)?_c('TextInput',{attrs:{"hasChanged":_vm.hasChanges('address'),"componentClass":'address-input',"newValue":_vm.addressChange,"label":_vm.$t('form.address'),"value":_vm.interimAddress,"disabled":true,"readonly":true,"rightIcon":_vm.getAskedChangeIcon('address'),"helpText":_vm.getHelpText('address')},on:{"updateAskedChange":function($event){return _vm.validateChanges($event, 'address')}}}):_vm._e(),(_vm.interimAddress)?_c('l-map',{ref:"map",staticClass:"map",attrs:{"center":_vm.mapMarkerPosition,"zoom":_vm.zoom,"options":{
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    clickableIcons: false,
                }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker',{attrs:{"lat-lng":_vm.mapMarkerPosition}})],1):_vm._e()],1):_vm._e()],1),_c('row',[_c('column',[(_vm.isAgency && _vm.interim.presentationText)?[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.presentationText"))+" ")]),_c('card',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.interim.presentationText[_vm.currentAgency._id])}})])]:_vm._e(),(_vm.isAgency && _vm.blacklist && _vm.blacklist.length)?[_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("page.interim.blacklist"))+" ")]),_c('card',[_c('blacklist',{attrs:{"clients":_vm.clients,"read":""},model:{value:(_vm.interim.blacklist),callback:function ($$v) {_vm.$set(_vm.interim, "blacklist", $$v)},expression:"interim.blacklist"}})],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }