
import {Component, Prop, Watch} from 'vue-property-decorator';
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import RoundRadio from "@/components/RoundRadio.vue";
import {Action, Getter, State} from "vuex-class";
import {ICountry, IAgencyDB, IUserDB, LabelValue, INationality, StoreAction, ICandidateDB} from "@/types";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import Dropzone from "@/components/Dropzone.vue";
import {formattedAddress, mandatoryField} from "@/helpers/commons";
import WeekUnavailabilities from "@/components/WeekUnavailabilities.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import Blacklist from "@/components/Blacklist.vue";
import InterimJobs from "@/components/InterimJobs.vue";
import moment from 'moment-timezone';
import { useWorkerAddIdentificateStore } from '@/store/WorkerAddIdentificate';
import GeocodeInput from '../form/v3/GeocodeInput.vue';
import { nextTick, onMounted } from 'vue';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
    name: "InterimForm",
    components: {
        InterimJobs,
        Blacklist,
        SelectAutocomplete,
        PicturePreview,
        WeekUnavailabilities,
        Dropzone,
        ListAutocompleteSelect,
        RoundRadio,
        InputGmapAutocomplete,
        GeocodeInput
    }
})
// export default
export default class InterimForm extends mixins(VModel) {
    @Getter('isAgency') isAgency!: LabelValue[];

    //proprietée
    @Prop() errors!: any;
    @Prop() globalErrors!: any;
    @Prop() clients!: any;
    @Prop(Boolean) noBlackList!: boolean;

    @Action('actions/validateInterimChange') validateInterimChange!: StoreAction;

    @Getter('isInteraction') isInteraction!: boolean;
    @Getter('isMigratingAgency') isMigratingAgency!: boolean;
    @Getter('getNationalitiesOptions') getNationalitiesOptions!: Array<INationality>;
    @Getter('getCountriesOptions') getCountriesOptions!: Array<ICountry>;
    @Getter('getBirthCountriesOptions') getBirthCountriesOptions!: Array<ICountry>;
    @State('currentAgency') currentAgency!: IAgencyDB;
    @State('currentUser') currentUser!: IUserDB;
    @State('collaborators') collaborators!: IUserDB[];
    @Getter('applicationOrigin') applicationOrigin!: LabelValue[];
    @Action('actions/getCandidate') getCandidate!: StoreAction;
    //data
    loading = true;

    parsePhoneNumber = parsePhoneNumber;

    cvToggle = false;
    moment = moment;
    mandatoryField = mandatoryField;

    //computed
    get interimAddress() {
        return formattedAddress(this.innerValue.address);
    }

    get evoliaAddress() {
        return formattedAddress(this.innerValue.evoliaAddress);
    }

    get hasLocation() {
        return this.innerValue.address?.location?.length === 2;
    }

    get cvPath() {
        return process.env.VUE_APP_API_STATIC_URL + "/" + this.innerValue?.cv?.path;
    }

    get availabilities() {
        return (this.innerValue.regularAvailabilities?.filter((a: any) => a) || []);
    }

    get blacklist() {
        return this.innerValue.blacklist;
    }

    get nationalitiesOptions() {
        return this.getNationalitiesOptions.map((n) => {
            return { label: n.name, value: n.code }
        });
    }

    get countriesOptions() {
        return this.getCountriesOptions.map((n) => {
            return { label: n.name, value: n.code }
        });
    }

    get birthCountriesOptions() {
        return [
            {label: "-", value: ""},
            ...this.getBirthCountriesOptions.map((n) => {
                return { label: n.name, value: n.code }
            })
        ]
    }

    get originOptions() {
        return this.applicationOrigin.map((option) => {
            return {
                ...option
            }
        })
    }

    get managerOptions() {
        return this.collaborators.concat(this.currentUser).map((m: IUserDB) => {
                const firstname = m.firstname.substring(0, 1).toUpperCase() + m.firstname.substring(1).toLowerCase();
                const lastname = m.lastname.substring(0, 1).toUpperCase() + m.lastname.substring(1).toLowerCase();
                return { label: firstname + " " + lastname, value: m._id}
            }).concat({label: '- Sans responsable -', value: ''}).sort((a: any, b: any) => {
                return (String(a.label).toString().toLowerCase() < String(b.label).toString().toLowerCase()? -1 : 1);
            });
    }

    get isEvoliaAgency() {
        return this.currentAgency.contractManagerType === 'EVOLIA';
    }

    autoNirKey():any{
        const  nir = this.innerValue.nir;
        if (!nir)  return null;

        const nirSansEspace = nir.replace(/ /g,'');
        const convertedNir = nirSansEspace.toLowerCase()
            .replace("2b", "18")
            .replace("2a", "19");
        const computeKey: number = 97 - parseInt(convertedNir) % 97;

        this.innerValue.nirKey = computeKey.toString();

        if(this.innerValue.nirKey.length === 1) this.innerValue.nirKey = "0" + this.innerValue.nirKey;

        this.$forceUpdate();
    }

    get isCreationMode(): boolean {
        return this.value._id === undefined;
    }
    // methods
    toggle() {
        this.cvToggle = !this.cvToggle;
    }

    openCV() {
        window.open(this.cvPath, "_blank");
    }

    placeChanged(place: any) {
        if (this.innerValue.address) {
            this.innerValue.address.name = "main";
            this.innerValue.address.street = [place.street_number, place.street].filter(s => s).join(' ');
            this.innerValue.address.complement = place.complement || this.innerValue.address.complement;
            this.innerValue.address.city = place.locality;
            this.innerValue.address.zip = place.zip;
            this.innerValue.address.countryCode = place.countryCode;

            this.innerValue.address.location = [place.longitude, place.latitude]
        }

        this.$forceUpdate();
    }

    shiftsChange(shift: string) {
        if (shift === 'night') {
            if (this.innerValue.nightShift === false) {
                this.innerValue.regularAvailabilities.forEach((av: any) => {
                    av.night = false;
                });
            } else {
                this.innerValue.regularAvailabilities.forEach((av: any, index: number) => {
                    av.night = true;

                    if (this.innerValue.weekendShift === false) {
                        if (index === 5 || index === 6) {
                            av.night = false;
                        }
                    }
                });
            }
        } else {
            if (this.innerValue.weekendShift === false) {
                this.innerValue.regularAvailabilities.forEach((av: any, index: number) => {
                    if (index === 5 || index === 6) {
                        av.morning = false;
                        av.afternoon = false;
                        av.evening = false;
                        av.night = false;
                    }
                });
            } else {
                this.innerValue.regularAvailabilities.forEach((av: any, index: number) => {
                    if (index === 5 || index === 6) {
                        av.morning = true;
                        av.afternoon = true;
                        av.evening = true;
                        av.night = true;
                    }

                    if (this.innerValue.nightShift === false) {
                        av.night = false;
                    }
                });
            }
        }
    }

    hasChanges(field: string) {
        return this.innerValue.askedChanges && this.innerValue.askedChanges[field] !== null && this.innerValue.askedChanges[field] !== undefined;
    }

    async updateField(field: string, validate: string) {
        const result = await this.validateInterimChange({ interimId: this.innerValue._id, field: field, all: false, action: "validate" });
        this.innerValue[field] = result[field];
        this.innerValue.askedChanges = result.askedChanges;
    }

    toggleNightShift() {
        this.$set(this.innerValue, 'nightShift', !this.innerValue.nightShift);

        this.shiftsChange('night');
        this.$forceUpdate();
    }

    toggleWeekendShift() {
        this.$set(this.innerValue, 'weekendShift', !this.innerValue.weekendShift);

        this.shiftsChange('weekend');
        this.$forceUpdate();
    }

    toggleVehicle() {
        this.$set(this.innerValue, 'vehicle', !this.innerValue.vehicle);

        this.$forceUpdate();
    }

    isB64(str:string) {
        return str?.indexOf('base64') !== -1;
    }

    //houks
    async beforeMount() {
        this.loading = true;

        if(typeof this.innerValue?.birthDate === "number") {
            this.innerValue.birthDate = moment(this.innerValue.birthDate).format('DD/MM/YYYY');
        }

        if (this.isCreationMode) {
            const store = useWorkerAddIdentificateStore();
            this.innerValue.firstname = store.findWorkerForm.firstname.value;
            this.innerValue.lastname = store.findWorkerForm.lastname.value;
            this.innerValue.email = store.findWorkerForm.email.value;
            this.innerValue.phone = store.findWorkerForm.phone.value;
            this.innerValue.birthDate = store.findWorkerForm.birthDate.value;
            this.innerValue.nir = store.findWorkerForm.nir.value;
            store.$reset();
        }

        if(this.$route.params.candidateId) {
            const relatedCandidate: ICandidateDB = await this.getCandidate(this.$route.params.candidateId);
            this.innerValue = Object.assign({}, this.innerValue, ...Object.keys(this.innerValue).map(k => k in relatedCandidate && { [k]: (relatedCandidate as any)[k] }));

            if(relatedCandidate.nationalityCode) {

                this.innerValue.nationalityCode = relatedCandidate.nationalityCode;
            }
            if(relatedCandidate.birthCountry) {
                this.innerValue.birthCountry = relatedCandidate.birthCountry;
            }
            if(relatedCandidate.birthPlace) {
                this.innerValue.birthPlace = relatedCandidate.birthPlace;
            }
            if(relatedCandidate.birthDate) {
                this.innerValue.birthDate = moment(relatedCandidate.birthDate).format('DD/MM/YYYY')
            }
            if(relatedCandidate.origin) {
                this.innerValue.originOfApplication = relatedCandidate.origin
            }
            if(relatedCandidate.phone) {
                this.innerValue.phone = parsePhoneNumber(relatedCandidate.phone, 'FR').formatInternational();
            }
            if(relatedCandidate.jobs) {
                this.innerValue.jobs = relatedCandidate.jobs.map(job => job.job);
            }
            if(relatedCandidate.address) {
                this.$set(this.innerValue, "address", relatedCandidate.address);
            }

            this.innerValue.candidateId = this.$route.params.candidateId;
        }

        if (this.innerValue?.cv?.path) {
            this.cvToggle = true;
        }

        if (! this.innerValue.manager) {
            if (!this.innerValue.managers) {
                this.innerValue.manager = ''
            } else {
                this.innerValue.manager = this.innerValue.managers[this.currentAgency._id]?this.innerValue.managers[this.currentAgency._id]:'';
            }
        }

        if (!this.innerValue.hasOwnProperty('vehicle')) {
            this.innerValue.vehicle = false;
        }

        if (!this.innerValue.hasOwnProperty('nightShift')) {
            this.innerValue.nightShift = true;
        }

        if (!this.innerValue.hasOwnProperty('weekendShift')) {
            this.innerValue.weekendShift = true;
        }

        if (!this.innerValue.blacklist) {
            this.innerValue.blacklist = [];
        }

        if (!this.availabilities?.length) {
            this.innerValue.regularAvailabilities = [
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
                {
                    morning: true,
                    afternoon: true,
                    evening: true,
                    night: true
                },
            ];
        }

        this.$set(this.innerValue, "address", {
            ...this.innerValue.address,
            complement: this.innerValue.evoliaAddress && this.innerValue.evoliaAddress.complement || ''
        });

        this.$nextTick(() => {
            this.loading = false;
        })

    }
}
