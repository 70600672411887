
import {Component, Prop} from 'vue-property-decorator';
import {
    ICountry,
    IAgencyDB,
    IGeoLocation,
    IUserDB,
    IWorkerStats,
    StoreAction,
    StoreMutation
} from "@/types";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import InlineField from "@/components/InlineField.vue";
import {Action, Getter, Mutation, State} from "vuex-class";
import InterimInformations from "@/components/interim/InterimInformations.vue";
import InterimAvailabilities from "@/components/interim/InterimAvailabilities.vue";
import InterimAdministrativeDocuments from "@/components/interim/InterimAdministrativeDocuments.vue";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import {capitalize} from "@/helpers/commons";
import {ROUTES} from "@/data";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import moment from 'moment-timezone';
import Row from '../grid/Row.vue';
import InterimStats from "@/components/InterimStats.vue";
import TitleManager from "@/mixins/TitleManager.vue";
import Avatar from '../Avatar.vue';
import RoundRadio from "@/components/RoundRadio.vue";
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import InactiveInterim from "@/components/modal/InactiveInterim.vue";
import { showModal } from '@/helpers/callables';
import InterimEvaluations from '@/components/interim/InterimEvaluations.vue';
import Tooltip from '../Tooltip.vue';
import NewIcon from '../NewIcon.vue';
import TextInput from '../form/TextInput.vue';
import ActionButton from "@/components/ActionButton.vue";
import InterimLogger from './InterimLogger.vue';

@Component({
    name: "InterimRead",
    components: {
        ActionButton,
        InterimStats,
        InterimAvailabilities,
        InterimInformations,
        InterimAdministrativeDocuments,
        InlineField,
        InputGmapAutocomplete,
        VuePdfApp,
        Row,
        Avatar,
        RoundRadio,
        ToggleSwitch,
        InactiveInterim,
        InterimEvaluations,
        Tooltip,
        NewIcon,
        TextInput,
        InterimLogger
    }
})
export default class InterimRead extends mixins(VModel, TitleManager) {
    $refs!: {
        link: any,
        popover: any
    };

    isOpen = false;

    // @Prop() interim!: IInterimDB;
    @Prop() interim!: any;
    @Prop() stats!: IWorkerStats;
    @Prop() clients!: any;
    @Prop(Boolean) editAvailabilities!: boolean;
    @Prop() interimUpdate!: any;

    @Mutation('addLink') addLink!: StoreMutation;

    @Getter('isAgency') isAgency!: boolean;
    @Getter('userContext') userContext!: string;
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('getCollaborator') getCollaborator!: any;
    @Getter('getCountriesOptions') getCountriesOptions!: Array<ICountry>;
    @Getter('getBirthCountriesOptions') getBirthCountriesOptions!: Array<ICountry>;
    @Getter('isInteraction') isInteraction!: boolean

    @State('currentAgency') currentAgency!: IAgencyDB;
    @State('currentUser') currentUser!: IUserDB;
    @State('collaborators') collaborators!: IUserDB[];

    @Action('actions/validateInterimChange') validateInterimChange!: StoreAction;
    @Action('actions/changeInterimActivity') changeInterimActivity!: StoreAction;
    @Action('actions/removeInterimFromMissions') removeInterimFromMissions!: StoreAction;


    apiUrl:string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/" ;
    center:IGeoLocation = {lat: 48.845617, lng: 2.2431133};
    showCV = false;
    showInactiveInterim = false;
    reason = "";
    reasonComplement = "";
    showConfirmRemoveDeactivatedInterimFromMission = false;
    loading = false;

    cvConfig = {
        toolbar: {
            toolbarViewerRight: false,
            toolbarViewerLeft: {
                findbar: false
            }
        },
        secondaryToolbar: false
    }

    moment = moment;

    get fullname() {
        return capitalize(this.interim.firstname) + " " + capitalize(this.interim.lastname)
    }

    get changedFullname() {
        return capitalize(this.interim.askedChanges?.firstname || '') + " " + capitalize(this.interim.askedChanges?.lastname || '');
    }

    get nameChanges() {
        return this.hasChanges('firstname') || this.hasChanges('lastname');
    }

    get isAvailabilities() {
        return this.innerValue === "availabilities";
    }

    get isInformations() {
        return this.innerValue === "informations";
    }

    get isStats() {
        return this.innerValue === "statistics";
    }

    get isAdministrativeDocuments() {
        return this.innerValue === "administrativeDocuments";
    }

    get isEvaluations() {
        return this.innerValue === 'evaluations';
    }

    get isFollowing() {
        return this.innerValue === 'following';
    }

    get wasACandidate() {
        return this.interim.candidateId ? true : false;
    }

    get goToCandidateView() {
        return {name: ROUTES.ATS.CANDIDATE._ROOT, params: {candidateId: this.interim.candidateId}};
    }

    get cvPath() {
        if (this.interim?.cv.path) {
            return process.env.VUE_APP_API_STATIC_URL + "/" + this.interim.cv?.path;
        }
    }

    get birthDate() {
        return moment(this.interim.birthDate).format("DD/MM/YYYY");
    }

    get birthPlace() {
        if(this.interim.birthPlace && (this.interim.birthPlace.trim().length > 0)) {
            return  " / " + this.interim.birthPlace;
        }
        else {
            return null;
        }
    }

    get birthCountry() {
        if(this.interim.birthCountry && (this.interim.birthCountry.trim().length > 0)) {
            const country = this.getBirthCountriesOptions.find((n) => n.code === this.interim.birthCountry)?.name || null;
            if (country) {
                if (country.toUpperCase().localeCompare(this.birthPlace ? this.birthPlace.toUpperCase() : "") !== 0) {
                    return "(" + country + ")";
                }
                else {
                    return null;
                }
            }
            else {
                return "(" + this.interim.birthCountry.trim().toLowerCase() + ")";
            }
        }
        else {
            return null;
        }
    }

    get isCV() {
        return this.interim?.cv.path?.endsWith('.pdf');
    }

    get isInactive() {
        if (this.interim?.inactiveByAgencies && this.interim?.inactiveByAgencies[this.currentAgency._id]) {
            return false;
        }
        return true;
    }

    get status() {
        if (this.interim?.inactiveByAgencies && this.interim?.inactiveByAgencies[this.currentAgency._id]) {
            return 'Inactif';
        }
        return 'Actif';
    }

    get cvImportedBy() {
        let c = this.getCollaborator(this.interim.cv?.collaboratorId);

        if (c) {
            return "Importé par " + `${capitalize(c.firstname)} ${capitalize(c.lastname)}` + "le " + moment(this.interim.cv?.registrationDate).format("DD/MM/YYYY");;
        } else if (this.interim.cv?.collaboratorId === this.currentUser._id) {
            return "Importé par " + `${capitalize(this.currentUser.firstname)} ${capitalize(this.currentUser.lastname)}` + " le " + moment(this.interim.cv?.registrationDate).format("DD/MM/YYYY");
        } else if (this.interim.cv?.collaboratorId === this.interim._id) {
            return "Importé par " + `${capitalize(this.interim.firstname)} ${capitalize(this.interim.lastname)}` + " le " + moment(this.interim.cv?.registrationDate).format("DD/MM/YYYY");
        }
    }

    changeStatus() {
        if (this.isInactive) {
            showModal.call(this, 'InactiveInterim');
        } else {
            this.submitActive();
        }
    }

    async submitActive() {
        this.loading = true;
        try {
            if (this.interim) {
                this.interim = await this.changeInterimActivity({
                    interimId: this.interim._id,
                    status: "active",
                });
            }
            this.$toast.open({
                position: "bottom-right",
                message: this.$t("page.interims.update").toString(),
                type: "success",
                duration: 3000
            });
        } catch (e) {
            console.log(e);
            this.$toast.open({
                position: "bottom-right",
                message: this.$t("page.interims.updateError").toString(),
                type: "error",
                duration: 3000
            });
        }
        this.loading = false;
    }

    async submitRemoveFromMissions() {
        this.loading = true;
        try {
            if (this.interim) {
                const result = await this.removeInterimFromMissions({
                    interimId: this.interim._id,
                    reason: this.reason?this.reason:'',
                    reasonComplement: this.reasonComplement?this.reasonComplement:''
                });
                this.showConfirmRemoveDeactivatedInterimFromMission = false;
                this.$modal.hide('ConfirmRemoveDeactivatedInterimFromMission');
            }
            this.loading = false;
        } catch(e) {
            console.log(e);
            this.showConfirmRemoveDeactivatedInterimFromMission = false;
            this.$modal.hide('ConfirmRemoveDeactivatedInterimFromMission');
            this.loading = false;
        }
    }

    async submitInactive(data: any) {
        this.loading = true;
        try {
            if (this.interim) {
                this.interim = await this.changeInterimActivity({
                    interimId: this.interim._id,
                    status: "inactive",
                    reason: data.reason,
                    reasonComplement: data.reasonComplement
                });
                this.showConfirmRemoveDeactivatedInterimFromMission = true;
                showModal.call(this, 'ConfirmRemoveDeactivatedInterimFromMission');
            }
            this.$modal.hide('InactiveInterim');
            this.$toast.open({
                position: "bottom-right",
                message: this.$t("page.interims.update").toString(),
                type: "success",
                duration: 3000
            });
        } catch (e) {
            console.log(e);
            this.$toast.open({
                position: "bottom-right",
                message: this.$t("page.interims.updateError").toString(),
                type: "error",
                duration: 3000
            });
        }
        this.loading = false;
    }

    test(event:any) {
        this.$emit('askChangesValidation', event);
    }

    hasChanges(field: string) {
        return this.interim.askedChanges && this.interim.askedChanges[field];
    }

    openChanges() {
        this.isOpen = true;
    }

    async validateChanges(action: string) {
        let result;
        if (this.hasChanges('firstname')) result = await this.validateInterimChange({ interimId: this.interim?._id, field: 'firstname', all: false, action })
        if (this.hasChanges('lastname')) result = await this.validateInterimChange({ interimId: this.interim?._id, field: 'lastname', all: false, action })

        this.$emit('askChangesValidation', result);
    }

    getHelpText(field: string) {
        if ((this.interim.askedChanges && this.interim.askedChanges[field])) {
            return "Modification à valider";
        }
    }

    getAskedChangeIcon(field: string) {
        if (this.interim.askedChanges && this.interim.askedChanges[field]) {
            return "design-system-exclamation";
        }
    }

    openCloseCv() {
        this.showCV = !this.showCV;
        setTimeout(() => {
            document.querySelector('.InterimInformations')?.classList.toggle('hideBelowContent');
        }, 50)
    }

    downloadCV() {
        this.$refs.link.click();
    }

    changeView(context: string) {
        this.innerValue = context;
    }

    beforeMount() {
        this.addLink({
            label: capitalize(this.interim.firstname) + " " + capitalize(this.interim.lastname),
            icon: 'user',
            route: {name: ROUTES.APP.INTERIM, params: {interimId:this.interim._id}},
            value: this.interim._id
        });
    }
}
