
import { Component, Prop } from "vue-property-decorator";
import {
    IAgencyDB,
    IJobDB,
    IWorkerStats,
    LabelValue,
    StoreAction,
    IInactivatedReasonDB,
    INationality,
    ICountry,
    IUserDB,
    IWorkerJob,
} from "@/types";
import { Action, Getter, State } from "vuex-class";
import WeekUnavailabilities from "@/components/WeekUnavailabilities.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import Blacklist from "@/components/Blacklist.vue";
import InterimStats from "@/components/InterimStats.vue";
import AskedField from "@/components/form/AskedField.vue";
import moment from "moment-timezone";
import { concat, formattedAddress, pluralize } from "@/helpers/commons";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import TextInput from "@/components/form/TextInput.vue";
import { parsePhoneNumber } from "libphonenumber-js";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

@Component({
    name: "InterimInformations",
    components: {
        InterimStats,
        Blacklist,
        MissionTile,
        WeekUnavailabilities,
        AskedField,
        LMap,
        LTileLayer,
        LMarker,
        TextInput,
    },
})
export default class InterimInformations extends mixins(TitleManager) {
    // --- props
    @Prop() interim!: any;
    @Prop() stats!: IWorkerStats;
    @Prop() clients!: any;
    // --- data
    @Getter("getJob") getJob!: (jobId: string) => IJobDB;
    @Getter("getJobOption") getJobOption!: (jobId: string) => LabelValue;
    @Getter("isAgency") isAgency!: boolean;
    @State("currentAgency") currentAgency!: IAgencyDB;
    @State("currentUser") currentUser!: IUserDB;
    @State("collaborators") collaborators!: IUserDB[];
    @Getter("getNationalitiesOptions")
    getNationalitiesOptions!: Array<INationality>;
    @Getter("getCountriesOptions") getCountriesOptions!: Array<ICountry>;

    @Action("actions/loadInactivatedReasons")
    loadInactivatedReasons!: StoreAction;
    @Action("actions/validateInterimChange")
    validateInterimChange!: StoreAction;

    inactivateReasonOptions: any[] = [];
    pageTitle = this.$t("page.interim.nav.informations");

    url: string = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    attribution: string =
        '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    parsePhoneNumber = parsePhoneNumber;

    // --- computed
    get hasMissions() {
        if (this.interim.missions) {
            return (
                this.interim.missions.currentMissions?.length ||
                this.interim.missions.futureMissions?.length ||
                this.interim.missions.lastMissions?.length
            );
        }
    }

    get zoom() {
        return (this.mapMarkerPosition && 16) || 8;
    }

    get mapMarkerPosition() {
        if (this.interim?.address?.location?.length) {
            return {
                lat: parseFloat(this.interim.address.location[1].toString()),
                lng: parseFloat(this.interim.address.location[0].toString()),
            };
        }
    }

    get regularAvailabilities() {
        return this.interim?.regularAvailabilities?.length
            ? this.interim.regularAvailabilities
            : null;
    }

    get blacklist() {
        return this.interim?.blacklist;
    }

    get inactivity() {
        return (
            this.isAgency &&
            this.interim?.inactiveByAgencies &&
            this.interim?.inactiveByAgencies[this.currentAgency._id]
        );
    }

    get inactivityReason() {
        if (this.inactivity) {
            const complement =
                this.interim?.inactiveByAgencies[this.currentAgency._id]
                    .complement;
            if (!complement) {
                const reasonSlug =
                    this.interim?.inactiveByAgencies[this.currentAgency._id]
                        .reasonSlug;
                return this.getReason(reasonSlug);
            } else {
                return complement;
            }
        }
    }

    get nationality() {
        return (
            this.getNationalitiesOptions.find(
                (n) => n.code === this.interim.nationalityCode
            )?.name || ""
        );
    }

    get changedNationality() {
        return (
            this.getNationalitiesOptions.find(
                (n) => n.code === this.interim.askedChanges?.nationalityCode
            )?.name || ""
        );
    }

    get birthDate() {
        return moment(this.interim.birthDate).format("DD/MM/YYYY");
    }

    get changedBirthDate() {
        if (!this.interim.askedChanges?.birthDate) return;

        return moment(this.interim.askedChanges?.birthDate).format(
            "DD/MM/YYYY"
        );
    }

    get changedBirthPlace() {
        return this.interim.askedChanges?.birthPlace;
    }

    get changedPhone() {
        const phone = this.hasChanges("phone");
        if (!phone) return;

        return parsePhoneNumber(phone, "FR").formatNational();
    }

    get changedAddress() {
        if (!this.interim.askedChanges?.address) return;
        const complement = this.interim?.address?.complement;
        return formattedAddress(this.interim.askedChanges.address, { complement });
    }

    get updatedJobs() {
        if (!this.interim.askedChanges?.jobs) return [];

        return this.interim.jobs.reduce((acc: any, job: any) => {
            const jobChanged = this.interim.askedChanges?.jobs?.find(
                (change: any) => job.jobId === change.jobId
            );

            if (!jobChanged) return acc;

            const { degreeLevel, experience } = jobChanged;
            return degreeLevel !== job.degreeLevel ||
                experience !== job["experience"]
                ? [...acc, { current: job, changes: jobChanged }]
                : acc;
        }, []);
    }

    get removedJobs() {
        if (!this.interim.askedChanges?.jobs) return [];

        return this.interim.jobs.reduce((acc: any, job: any) => {
            const removedJob = this.interim.askedChanges?.jobs?.find(
                (change: any) => job.jobId === change.jobId
            );
            return !removedJob ? [...acc, job] : acc;
        }, []);
    }

    get unchangedJobs() {
        if (!this.interim.askedChanges?.jobs) return this.interim.jobs;

        return this.interim.jobs.reduce((acc: any, job: any) => {
            const jobChanged = this.interim.askedChanges?.jobs?.find(
                (change: any) => job.jobId === change.jobId
            );

            if (!jobChanged) return acc;

            const { degreeLevel, experience } = jobChanged;
            return degreeLevel === job.degreeLevel &&
                experience === job.experience
                ? [...acc, job]
                : acc;
        }, []);
    }

    get addedJobs() {
        if (!this.interim.askedChanges?.jobs) return [];

        const jobs =
            this.interim.askedChanges?.jobs.length < this.interim.jobs.length
                ? this.interim.jobs || []
                : this.interim.askedChanges.jobs || [];

        return jobs.reduce((acc: any, job: any) => {
            const isAsked = this.interim.askedChanges.jobs?.find(
                (change: any) => job.jobId === change.jobId
            );
            const isExisting = this.interim.jobs?.find(
                (change: any) => job.jobId === change.jobId
            );

            return !isExisting && isAsked ? [...acc, job] : acc;
        }, []);
    }

    get birthPlace() {
        const country = this.getCountriesOptions.find((n) => n.code === this.interim.birthPlace)?.name;
        if (country) {
            return "(" + country + ")";
        } else {
            return null;
        }
    }

    get interimAddress() {
        if (!this.interim?.address) return "";
        const complement = this.interim.address.complement;
        return formattedAddress(this.interim.address, { complement });
    }

    get nirStr() {
        return `${this.interim.nir}${this.interim.nirKey}`;
    }

    get managerName() {
        let firstname,
            lastname = "";
        let noManager = "sans responsable";
        if (this.interim.managers) {
            const managers = { ...this.interim.managers };
            if (managers && managers[this.currentAgency._id]) {
                if (this.currentUser._id === managers[this.currentAgency._id]) {
                    firstname = this.currentUser.firstname;
                    lastname = this.currentUser.lastname;
                } else {
                    const collaborateur: IUserDB | undefined =
                        this.collaborators.find((c) => {
                            return c._id === managers[this.currentAgency._id];
                        });
                    if (collaborateur) {
                        firstname = collaborateur.firstname;
                        lastname = collaborateur.lastname;
                    }
                }
            } else {
                return noManager;
            }
        } else {
            return noManager;
        }
        firstname = firstname
            ? firstname.substring(0, 1).toUpperCase() +
              firstname.substring(1).toLowerCase()
            : "";
        lastname = lastname
            ? lastname.substring(0, 1).toUpperCase() +
              lastname.substring(1).toLowerCase()
            : "";
        return firstname + " " + lastname;
    }

    get addressChange() {
        const changes = this.hasChanges("address");
        return formattedAddress(changes);
    }

    get jobChanges() {
        const nbChanges =
            this.removedJobs.length +
            this.addedJobs.length +
            this.updatedJobs.length;
        return nbChanges
            ? `${nbChanges} ${pluralize("Modification", nbChanges)}`
            : "";
    }

    get lastContactDate() {
        return {
            date: moment(this.interim.lastAgencyContact.timestamp).format(
                "DD/MM/YYYY"
            ),
            collaborator: this.interim.lastAgencyContact.collaboratorIdentity,
        };
    }

    isNightAvailable() {
        for (const regularAvailabiliy of this.regularAvailabilities) {
            if (regularAvailabiliy.night === true) return true;
        }
        return false;
    }

    isWeekEndAvailable() {
        let index = 0;
        for (const regularAvailabiliy of this.regularAvailabilities) {
            if (index === 5 || index === 6) {
                if (
                    regularAvailabiliy.afternoon === true ||
                    regularAvailabiliy.evening === true ||
                    regularAvailabiliy.morning === true ||
                    regularAvailabiliy.night === true
                )
                    return true;
            }
            index += 1;
        }
        return false;
    }

    hasChanges(field: string) {
        return this.interim.askedChanges && this.interim.askedChanges[field];
    }

    getAskedChangeIcon(field: string) {
        if (this.interim.askedChanges && this.interim.askedChanges[field]) {
            return "design-system-exclamation";
        }
    }

    getHelpText(field: string) {
        if (this.interim.askedChanges && this.interim.askedChanges[field]) {
            return "Modification à valider";
        }
    }

    getReason(reasonSlug: string): string {
        const index = this.inactivateReasonOptions.findIndex((element) => {
            return element.reasonSlug == reasonSlug;
        });
        if (index! - -1) {
            return this.inactivateReasonOptions[index].reason;
        } else {
            return "";
        }
    }

    getJobName(job: IWorkerJob) {
        return this.getJobOption(job.jobId).label;
    }

    // --- methods
    getCertificationName(jobId: string, certId: string) {
        let j: any = this.getJob(jobId);

        if (j) {
            let c = (j.certifications || []).find(
                (cert: any) => cert._id === certId
            );

            if (c) {
                return c.name;
            }
        }
        return "";
    }

    getDegreeLevel(degreeLevelSlug: string): string {
        return degreeLevelSlug.toUpperCase();
    }

    async validateChanges(action: string, field: string) {
        const result = await this.validateInterimChange({
            interimId: this.interim?._id,
            all: false,
            field,
            action,
        });
        this.$emit("askChangesValidation", result);
    }

    async getReasonOptions() {
        (await this.loadInactivatedReasons()).forEach(
            (element: IInactivatedReasonDB) => {
                this.inactivateReasonOptions.push({
                    reasonSlug: element.slug,
                    reason: element.reason,
                });
            }
        );
    }

    beforeMount() {
        this.getReasonOptions();
    }
}
