
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {Action} from "vuex-class";
    import {StoreAction} from "@/types";

    enum BlackListFrom {
        company = "company",    // c'est l'entreprise qui l'a blacklisté.
        worker = "worker",   // c'est l'intérimaire qui l'a blacklisté
        both = "both"   // les 2 ne veulent plus travailer ensemble
    }

    @Component({
        name: "AddBlacklistItem"
    })
    export default class AddBlacklistItem extends mixins(ModalMixin) {
        name = "AddBlacklistItem";
        innerLoading = false;
        from = "company";
        companyId = "";
        companyName = "";

        @Prop() blacklist!:any;
        @Prop() clients!: any;

        @Action('actions/loadCompanies') loadCompanies!: StoreAction;

        get clientsOptions() {
            return this.clients?.filter((a:any) => a.name && !(this.blacklist.find((b:any) => b.companyId === a._id))).map((c:any) => ({label: c.name, value: c._id})).sort((a:any, b:any) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : 0);
        }

        get exist() {
            return this.clientsOptions.find((c:any) => c.value === this.companyId);
        }

        get fromOptions() {
            return [
                {
                    label: "L'entreprise ne souhaite plus travailler avec cet intérimaire",
                    value: BlackListFrom.company
                },
                {
                    label: "L'intérimaire ne souhaite plus travailler avec cette entreprise",
                    value: BlackListFrom.worker
                },
                {
                    label: "Les deux ne désirent plus travailler ensemble.",
                    value: BlackListFrom.both
                },
            ]
        }
        change(companyId:string) {
            this.companyName = this.clientsOptions.find((c:any) => c.value === companyId).label;
        }

        async init() {
            this.innerLoading = true;
            try {
                this.companyId = this.clientsOptions[0].value;
                this.companyName = this.clientsOptions[0].label;
            } catch (e) {
                console.log(e);
            }
            this.innerLoading = false;
        }

        beforeMount() {
            this.init();
        }
    }
