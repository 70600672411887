<script>
    import { Pie } from "vue-chartjs";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

    export default {
        extends: Pie,
        props: {
            chartdata: Object,
            noFloatingLabel: Boolean,
            displayLegend: {
                default: true,
                type: Boolean
            },
            stacked: {
                default: false,
                type: Boolean
            },
        },
        watch: {
            chartdata(n) {
                this.draw();
            }
        },
        methods: {
            draw() {
                this.renderChart(this.chartdata, {
                    maintainAspectRatio: false,
                    responsive: true,
                    tooltips: false,
                    legend: {
                        position: 'bottom'
                    },
                    options: {
                        tooltips: {
                            enable: false
                        }
                    },
                    scales: {
                    },
                    plugins: {
                        datalabels: {
                            anchor :'center',
                            align :'center',
                            color: "white",
                            font: {
                                weight: 500,
                                family:"Roboto, Helvetica, Arial, sans-serif",
                                size: 15
                            },
                            formatter: (value, context) => {
                                return this.noFloatingLabel ? "" : (value != 0 ? value + "%" : '');
                            }
                        }
                    }
                });
            }
        },
        mounted() {
            this.addPlugin(ChartJsPluginDataLabels);
            this.draw();
        }
    };
</script>
