
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import Tile from "@/components/tileContent/Tile.vue";
    import {KeyFigure} from "@/types";
    import {capitalize} from "@/helpers/commons";

    @Component({
        name: "NumberTile",
        components: {Tile}
    })
    export default class NumberTile extends Vue {
        @Prop(Object) readonly number!: KeyFigure;

        get label() {
            return capitalize(this.number.label);
        }
    }
