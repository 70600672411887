
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {showModal} from "@/helpers/callables";
import AddBlacklistItem from "@/components/modal/AddBlacklistItem.vue";
import {Action} from "vuex-class";
import {StoreAction} from "@/types";

@Component({
    name: "Blacklist",
    components: {AddBlacklistItem}
})
export default class Blacklist extends mixins(VModel) {
    @Prop(Boolean) read!: boolean;
    @Prop() clients!: any;

    @Action('actions/loadCompanies') loadCompanies!: StoreAction;

    loading = false;
    showAddBlacklistItem = false;

    fromBlacklist(item:any) {
        if (item.from === "both") {
            return this.$t('page.interim.bothBlacklist');
        }
        if (item.from === "company") {
            return this.$t('page.interim.companyBlacklist');
        }
        if (item.from === "worker") {
            return this.$t('page.interim.interimBlacklist');
        }
    }

    removeCompany(index:number) {
        return () => {
            this.innerValue.splice(index, 1);
            this.$emit('input', this.innerValue);
        }
    }

    openAddBlacklistItem() {
        showModal.call(this, "AddBlacklistItem");
    }

    addBlacklistItem(data:any) {
        this.innerValue.push({
            from: data.from,
            companyId: data.companyId,
            companyName: data.companyName
        })
        this.$emit('input', this.innerValue);
        this.$modal.hide('AddBlacklistItem');
    }
}
