
import {Component, Prop} from 'vue-property-decorator';
import clonedeep from "lodash.clonedeep";
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {IAgencyDB, IDegreeLevelDB, IJobDB, LabelValue, StoreAction} from "@/types";
import {Action, Getter, State} from "vuex-class";
import SelectAutocomplete from '@/components/SelectAutocomplete.vue';
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import AlertModal from "@/components/modal/AlertModal.vue";
import {showModal} from "@/helpers/callables";

@Component({
    name: "InterimJobs",
    components: {AlertModal, ListAutocompleteSelect, SelectAutocomplete}
})
export default class InterimJobs extends mixins(VModel) {
    @Prop() context!: string;
    @Prop() errors!: any;

    loading = false;
    certOptions: LabelValue[][] = [];
    degreeLevelOptions: LabelValue[] = [];
    selectedJobs = [];
    selectedDegreeLevels = [];
    selectedExperiences = [];
    showAlertModal = false;

    @State("currentAgency") currentAgency!: IAgencyDB;
    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('getJob') getJob !: any;
    @Action('actions/loadDegreeLevels') loadDegreeLevels!: StoreAction;
    @Getter('interimExperienceOptions') interimExperienceOptions!: LabelValue[];
    @Getter('jobCertificationsOptions') jobCertificationsOptions!: (jobId: string) => LabelValue[];
    @Getter('isInteraction') isInteraction!: boolean;

    get isCandidate() {
        return this.context === "candidate";
    }

    removeJob(index: number) {
        const job = this.innerValue.jobs[index];
        if(job && (!job.newJob) && this.currentAgency && this.currentAgency.contractManagerIdentifier) {
            showModal.call(this, 'AlertModal');
            return;
        }

        this.innerValue.jobs.splice(index, 1);
        this.selectedJobs.splice(index, 1);
        this.$forceUpdate();
    }

    addJob() {
        let jobs = this.innerValue.jobs;
        if((jobs === undefined) || (jobs === null)) {
            jobs = [];
        }
        jobs.push({jobId: null, _id: null, certifications: [], degreeLevel: 'nodegree', experience: 'beginner', newJob: true});
        this.innerValue.jobs = jobs;
        this.$forceUpdate();
    }

    addHability(index: number) {
        this.innerValue.jobs[index].certifications = [];
        this.$forceUpdate();
    }

    removeHability(index: number) {
        this.innerValue.jobs[index].certifications = undefined;
        this.$forceUpdate();
    }

    reloadCertificationsOptions(jobId: any, index: number) {
        if (!this.certOptions[index]) {
            this.certOptions[index] = [];
        }
        if (typeof jobId === 'number') {
            this.certOptions[index].length = 0;
        } else {
            if (typeof jobId === 'string') {
                this.certOptions[index] = this.jobCertificationsOptions(jobId);
            } else if (jobId) {
                this.certOptions[index] = this.jobCertificationsOptions(jobId[0].value);
            }
        }
        this.$forceUpdate();
    }

    changeJob(job: IJobDB, newJob: LabelValue[], index: number) {
        const newJobValue = newJob && newJob[0];
        let currentJob = this.innerValue.jobs[index];
        if (newJobValue && (currentJob._id !== newJobValue.value && currentJob.jobId !== newJobValue.value)) {
            this.reloadCertificationsOptions(newJobValue.value, index);
            currentJob = {
                ...currentJob,
                jobId: newJobValue.value,
                _id: newJobValue.value,
                certifications: null,
                degreeLevel: 'nodegree',
                experience: 'beginner',
            }
            this.innerValue.jobs[index] = currentJob;
        }
        this.$forceUpdate();
    }

    enableCertification(job:any) {
        if (job.jobId != null && (job.certifications && job.certifications)) {
            return true;
        }
        else {
            return (job.jobId === null);
        }
    }

    selectableDegrees(job: any) {
        return this.degreeLevelOptions.map((d:any) => {
            return {
                ...d,
                selected: d.value === job.degreeLevel
            };
        });
    }

    selectedValuesUpdate() {
        this.selectedJobs = this.innerValue.jobs.map((j:any) => {
            const job = this.getJob(j.jobId || j._id)
            return { label: job.name || job.jobName, value: job._id || job.jobId, selectable: !job.notSelectable };
        });

        this.selectedDegreeLevels = this.innerValue.jobs.map((j:any) => {
            const job = this.getJob(j.jobId || j._id)
            return job.degreeLevel || 'nodegree' ;
        });

        this.selectedExperiences = this.innerValue.jobs.map((j:any) => {
            const job = this.getJob(j.jobId || j._id)
            return job.experience || 'beginner' ;
        });
    }

    async initDegreeLevelOptions() {
        const degreeLevels: IDegreeLevelDB[] =  await this.loadDegreeLevels();
        degreeLevels.forEach((degreeLevel: IDegreeLevelDB) => {
            this.degreeLevelOptions.push({label: degreeLevel.name, value: degreeLevel.slug});
        })
    }

    async init() {
        this.loading = true;
        try {
            await this.initDegreeLevelOptions();

            this.selectedValuesUpdate();

            this.innerValue.jobs.forEach((job: any, index: number) => {
                const certs = this.jobCertificationsOptions(job._id || job.jobId);
                this.certOptions[index] = clonedeep(certs);
                this.$set(
                    this.innerValue.jobs[index], 'certifications',
                    job.certifications ? job.certifications.map((cert: any) => {
                        const c = certs.find((c: any) => c.value === cert );
                        return {
                            label: clonedeep(c?.label || ''),
                            value: cert
                        }})
                    : []
                );
            })



        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
