
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IWorkerStats} from "@/types";
import Pie from "@/components/charts/pie.vue";
import NumberTile from "@/components/tileContent/NumberTile.vue";
import Hbar from "@/components/charts/hbar.vue";
import moment from "moment-timezone";
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";

@Component({
    name: "InterimStats",
    components: {Hbar, NumberTile, Pie}
})
export default class InterimStats extends mixins(TitleManager) {
    @Prop() data!: IWorkerStats;
    pageTitle = this.$t('page.interim.nav.statistics');

    get barData() {
        return {
            labels: [
                this.$t('page.statistics.interims.missions.proposed'),
                this.$t('page.statistics.interims.missions.confirmed'),
                this.$t('page.statistics.interims.missions.rejectedByAgency')
            ],
            datasets: [
                {
                    // label: this.$t('page.statistics.interims.missions.proposed'),
                    backgroundColor: "#5E72EB",
                    hoverBackgroundColor: "#5E72EB",
                    data: [this.data.missions.proposed, this.data.missions.confirmed, this.data.missions.rejectedByAgency],
                },
                // {
                //     // label: this.$t('page.statistics.interims.missions.confirmed'),
                //     backgroundColor: "#4ADEDE",
                //     hoverBackgroundColor: "#4ADEDE",
                //     data: [this.data.missions.confirmed],
                // },
                // {
                //     // label: this.$t('page.statistics.interims.missions.rejectedByAgency'),
                //     backgroundColor: "#cc0033",
                //     hoverBackgroundColor: "#cc0033",
                //     data: [this.data.missions.rejectedByAgency],
                // }
            ]
        };
    }

    get pieData() {
        let t = Number(this.data.missions.proposed);
        const { proposed, accepted, aborted, declined, notconfirmed } = this.data.missions;
        const total = proposed;

        const totalAccepted = (accepted * 100 / total).toFixed(2) || 0;
        const totalDeclined = (declined * 100 / total).toFixed(2) || 0;
        const totalWithouthAnswers = ((proposed - accepted - declined - aborted - notconfirmed) * 100 / total).toFixed(2) || 0;
        const totalAborted = (aborted * 100 / total).toFixed(2) || 0;


        return {
            labels: [
                this.$t('page.statistics.interims.missions.accepted'),
                this.$t('page.statistics.interims.missions.declined'),
                this.$t('page.statistics.interims.missions.withoutAnswers'),
                this.$t('page.statistics.interims.missions.aborted'),
            ],
            datasets: [
                {
                    backgroundColor: ["#6AB547", "#5D697E", "#FFA630", "#cc0033"],
                    hoverBackgroundColor: ["#6AB547", "#5D697E", "#FFA630", "#cc0033"],
                    data: [
                        totalAccepted,
                        totalDeclined,
                        totalWithouthAnswers,
                        totalAborted
                    ]
                }
            ]
        };
    }

    get hoursWorkedCurrentMonth() {
        return {
            label: this.$t('page.statistics.interims.hoursWorkedCurrentMonth'),
            value: this.formatHour(this.data.hoursWorkedCurrentMonth)
        }
    }

    get hoursWorkedCurrentYear() {
        return {
            label: this.$t('page.statistics.interims.hoursWorkedCurrentYear'),
            value: this.formatHour(this.data.hoursWorkedCurrentYear)
        }
    }

    get averageResponseTime() {
        return {
            label: this.$t('page.statistics.interims.averageResponseTime'),
            value: this.formatHour(this.data.averageResponseTime)
        }
    }

    get daysSinceLastMission() {
        return {
            label: this.$t('page.statistics.interims.daysSinceLastMission'),
            value: this.data.daysSinceLastMission && this.data.daysSinceLastMission > 0 ? this.$t('page.statistics.interims.since') + " " + this.data.daysSinceLastMission + this.$t('page.statistics.interims.shortDay', this.pluralDays(this.data.daysSinceLastMission)) : '-'
        }
    }

    get daysBeforeNextMission() {
        return {
            label: this.$t('page.statistics.interims.daysBeforeNextMission'),
            value: this.data.daysBeforeNextMission && this.data.daysBeforeNextMission > 0 ? this.$t('page.statistics.interims.in') + " " + this.data.daysBeforeNextMission + this.$t('page.statistics.interims.shortDay', this.pluralDays(this.data.daysBeforeNextMission)) : '-'
        }
    }

    formatHour(hour:any) {
        const d = moment.duration(hour * 1000);
        let h = d.asHours().toFixed(2).split('.')[0];
        let m = parseInt((d.asMinutes() - (h * 60)).toString());
        return h + "h" + ((m && (m.toString().length === 1 ? "0" + m : m) + "m") || '');
    }

    pluralDays(nb:number | null) {
        return {plural: nb && nb > 1 ? 's' : ''};
    }
}
