

import {Component, Prop, Vue} from "vue-property-decorator";
import RoundRadio from "@/components/RoundRadio.vue";

@Component({
        name: "ToggleSwitch",
        components: {
            RoundRadio
        }
    })
    export default class ToggleSwitch extends Vue {
        @Prop(String)   switchName!: string;
        @Prop()  value!: boolean;

        toggle() {
            this.$emit('toggle');
        }
    }
