<script>
    import { HorizontalBar } from "vue-chartjs";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

    export default {
        extends: HorizontalBar,
        props: {
            chartdata: Object,
            legendPosition: {
                default: 'bottom',
                type: String
            },
            displayLegend: {
                default: true,
                type: Boolean
            },
            stacked: {
                default: false,
                type: Boolean
            },
        },
        watch: {
            chartdata(n) {
                this.draw();
            }
        },
        methods: {
            draw() {
                this.renderChart(this.chartdata, {
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        position: this.legendPosition,
                        display: this.displayLegend
                    },
                    // scales: {
                    //     xAxes: [{
                    //         ticks: {
                    //             scaleShowLabels:false,
                    //         }
                    //     }],
                    //     yAxes: [{
                    //         ticks: {
                    //             scaleShowLabels:false,
                    //             mirror: true
                    //         }
                    //     }]
                    // },
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }
                            }
                        ]
                    },
                    tooltips: false,
                    // tooltips: {
                    //     callbacks: {
                    //         title: function(chart, data) {
                    //             return data.labels[chart[0].datasetIndex];
                    //         }
                    //     }
                    // },
                    plugins: {
                        datalabels: {
                            anchor :'center',
                            align :'center',
                            color: "white",
                            font: {
                                weight: 500,
                                family:"Roboto, Helvetica, Arial, sans-serif",
                                size: 13
                            },
                            formatter: function(value, context) {
                                return value != 0 ? value : '';
                            }
                        }
                    }
                });
            }
        },
        mounted() {
            this.addPlugin(ChartJsPluginDataLabels);
            this.draw();
        }
    };
</script>
