
import {Component, Prop} from 'vue-property-decorator';
import Card from "@/components/Card.vue"
import {Action, Getter, State} from "vuex-class";
import InlineField from "@/components/InlineField.vue"
import AskedField from '@/components/form/AskedField.vue';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import AddHabilitationModal from "@/components/modal/AddHabilitationModal.vue"
import moment from "moment-timezone";
import { showModal } from '@/helpers/callables';
@Component({
    name: "EditAdministrativeDocument",
    components: {AskedField, Card, InlineField, AddHabilitationModal}
})
export default class EditAdministrativeDocument extends mixins(TitleManager) {
    @Prop() administrativeDocuments!: {habilitations?: {title: string, code: string, expirationDate: number}[], BTPCard?: number, medicalCheckup?: number, residencePermit?: number};
    moment = moment
    showAddHabilitationModal: boolean = false

    @Getter('getHabilitations') getHabilitations!: any;
    setHabilitationDate(newHabilitationDate: number, habilitationIndex: number) {
        if(this.administrativeDocuments?.habilitations && this.administrativeDocuments.habilitations[habilitationIndex]) {
            this.administrativeDocuments.habilitations[habilitationIndex].expirationDate = newHabilitationDate
            this.$forceUpdate()
        }
    }

    setDocumentDate(document: 'BTPCard' | 'medicalCheckup' | 'residencePermit', newExpirationDate: number) {
        this.administrativeDocuments[document] = newExpirationDate
        this.$forceUpdate()
    }

    addHabilitationButton() {
        this.showAddHabilitationModal = true
        showModal.call(this, 'AddHabilitationModal');
    }

    addHabilitation($event: any) {
        if(this.administrativeDocuments.habilitations) {
            this.administrativeDocuments.habilitations[this.administrativeDocuments.habilitations?.length - 1] = $event
        }
    }
}
