
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {Getter} from "vuex-class";
    import {Action} from "vuex-class";
    import {IInactivatedReasonDB, LabelValue, StoreAction} from "@/types";
    import ActionButton from "@/components/ActionButton.vue";

    @Component({
        name: "InactiveInterim",
        components: {ActionButton}
    })
    export default class InactiveInterim extends mixins(ModalMixin) {
        name = "InactiveInterim";

        @Prop() nbInterim: any;
        @Prop() custom_title: any;
        @Prop() description: any;

        @Action('actions/loadInactivatedReasons') loadInactivatedReasons!: StoreAction;

        reason = "";
        reasonComplement ="";
        inactivateReasonOptions: LabelValue[] = [];

        async getDeleteReasons() {
            this.inactivateReasonOptions = [];
            (await this.loadInactivatedReasons()).forEach((element: IInactivatedReasonDB) => {
                this.inactivateReasonOptions.push({label: element.reason , value: element.slug });
            });
        }

        isAutre(): boolean {
            return this.reason === "autre";
        }

        beforeMount() {
            this.getDeleteReasons();
            this.reason = this.inactivateReasonOptions.length>0?this.inactivateReasonOptions[0].value:"";
        }

    }
