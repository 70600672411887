
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter, Mutation, State} from "vuex-class";

import {
    IAgencyDB,
    IInterimDB,
    IWorkerStats,
    LabelValue,
    StoreAction,
    StoreMutation,
    INationality,
    IUserDB
} from "@/types";
import InterimRead from "@/components/interim/InterimRead.vue";
import InterimForm from "@/components/interim/InterimForm.vue";
import EditAdministrativeDocument from "@/components/interim/EditAdministrativeDocument.vue";
import clonedeep from "lodash.clonedeep";
import InterimAvailabilities from "@/components/interim/InterimAvailabilities.vue";
import {showModal} from "@/helpers/callables";
import LockedAction from '@/components/modal/LockedAction.vue';
import moment from 'moment-timezone';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";

@Component({
    name: "Interim",
    components: {InterimAvailabilities, InterimForm, InterimRead, EditAdministrativeDocument, LockedAction }
})
export default class Interim extends mixins(TitleManager) {
    $refs!: {
        container: any
    };
    loading = false;
    innerLoading = false;
    interim: IInterimDB | null = null;
    tmpInterim: IInterimDB | null = null;
    stats: IWorkerStats | null = null
    mode: "read" | "edit" | "unavs" | "editAdministrativeDocument" = "read";
    clients:any = [];
    errors:any = {};
    globalErrors:any = [];
    view: "informations" | "availabilities" | "administrativeDocuments" | "statistics" | "evaluations" | "following" = "informations"
    showConfirmUnregister = false;
    showConfirmRegisterAgain = false;
    showLockedAction = false;
    moment = moment

    @Action('actions/loadInterim') loadInterim!: StoreAction;
    @Action('actions/interimSynchronization') interimSynchronization!: StoreAction;
    @Action('actions/getInterimStats') getInterimStats!: StoreAction;
    @Action('actions/loadCompanies') loadCompanies!: StoreAction;
    @Action('actions/editInterim') editInterim!: StoreAction;
    @Action('actions/saveUnavailability') saveUnavailability!: StoreAction;
    @Action('actions/sendInterimInvitationOne') sendInterimInvitationOne!: StoreAction;
    @Action('actions/resendInterimInvitationOne') resendInterimInvitationOne!: StoreAction;
    @Action('actions/interimNoLongerRegistered') interimNoLongerRegistered!: StoreAction;
    @Action('actions/interimAgainRegistered') interimAgainRegistered!: StoreAction;
    @Action('actions/validateInterimChange') validateInterimChange!: StoreAction;

    @Mutation('removeLink') removeLink!: StoreMutation;
    @State('currentAgency') currentAgency!: IAgencyDB;
    @State('currentUser') currentUser!: IUserDB;

    @Getter('getJob') getJob !:any;
    @Getter('userContext') userContext!: string;
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('isInteraction') isInteraction!: boolean;
    @Getter('isInteractionLimited') isInteractionLimited!: boolean
    @Getter('getNationalitiesOptions') getNationalitiesOptions!: Array<INationality>;
    @Getter('isAuthorizedAgency') isAuthorizedAgency!: boolean

    @Watch('$route.params.interimId')
    onInterimId() {
        this.init();
    }



    get registered() {
        // @ts-ignore;
        return this.interim?.status !== "no_longer_registered";
    }

    get hasChanges() {
        // @ts-ignore
        return this.interim?.askedChanges && Object.entries(this.interim?.askedChanges)
                                                   .filter(([key, value]) => key !== 'gender')
                                                   .some(([key, value]) => value);
    }

    get isInformationsView() {
        return this.view === "informations";
    }

    get isAdministrativeDocumentView() {
        return this.view === "administrativeDocuments"
    }

    get isStatistics() {
        return this.view === "statistics";
    }

    get isEvaluations() {
        return this.view === "evaluations";
    }

    get isFollowing() {
        return this.view === 'following';
    }

    get editAction() {
        return this.isInformationsView ? this.$t('page.interim.editInformations')
                : this.$t('page.interim.editUnavailabilities');
    }

    get interimId() {
        return this.$route.params.interimId;
    }

    get isImported() {
        return this.interim?.status === "imported";
    }

    get isInvited() {
        return this.interim?.status === "invited";
    }

    get isReadMode() {
        return this.mode === "read";
    }

    get isEditMode() {
        return this.mode === "edit";
    }

    get isUnavsMode() {
        return this.mode === "unavs";
    }

    get isEditAdministrativeDocumentMode() {
        return this.mode === 'editAdministrativeDocument'
    }

    get isSynchronizable() {
        return (this.isReadMode && this.isInteraction);
    }

    goEdition() {
        this.tmpInterim = clonedeep(this.interim);
        switch(this.view) {
            case 'informations':
                this.mode = "edit"
                break;
            case 'availabilities':
                this.mode = "unavs"
                break;
            case 'administrativeDocuments':
                this.mode = "editAdministrativeDocument"
                break;
            default:
                break
        }
    }

    quitEdition() {
        this.mode = 'read';
        if (this.isUnavsMode) {
            this.view = "availabilities";
        }
        this.$nextTick(() => {
            this.$refs.container.$el.scrollTop = 0;
        })
        this.errors = {};
    }

    NaNOrValue(option: LabelValue) {
        return option && (typeof option.value === "number" ? option.label : option.value)
    }

    NaNOrValueMore(option: LabelValue) {
        return option && (typeof option.value === "number" ? ['label', option.label] : ['value', option.value]);
    }

    displayUnregister() {
        showModal.call(this, "ConfirmUnregister");
    }

    displayRegisterAgain() {
        showModal.call(this, "ConfirmRegisterAgain");
    }

    displaylock() {
        showModal.call(this, 'LockedAction');
    }

    clickAction(other:any) {
        this.isInteractionLimited ? this.displaylock() : other();
    }

    async validateChanges() {
        this.interim = await this.validateInterimChange({ interimId: this.interim?._id, field: '', all: true, action: "validate" });
        this.tmpInterim = clonedeep(this.interim);
    }

    registerLastContact() {
        console.log('registerLastContact')
        this!.interim!.lastAgencyContact = {
            timestamp: moment().valueOf(),
            collaboratorIdentity: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
            collaboratorId: this.currentUser._id,
        }
        this.tmpInterim = clonedeep(this.interim);

        this.update();
    }

    async unregister() {
        this.innerLoading = true;
        try {
            const result = await this.interimNoLongerRegistered(this.interim?._id);
            this.$modal.hide('ConfirmUnregister');
            this.init();
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async registerAgain() {
        this.innerLoading = true;
        try {
            const result = await this.interimAgainRegistered(this.interim?._id);
            this.$modal.hide('ConfirmRegisterAgain');
            this.init();
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async invite() {
        this.innerLoading = true;
        try {
            const result = await this.sendInterimInvitationOne({
                interimId: this.interim?._id
            });
            this.init();
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async resend() {
        this.innerLoading = true;
        try {
            const result = await this.resendInterimInvitationOne({interimId: this.interim?._id});
            await this.init();
        } catch (e) {
            console.log(e);
        }
        this.innerLoading = false;
    }

    async synchronizeInterim() {
        try {
            this.loading = true;
            const updatedInterim = await this.interimSynchronization(this.interimId);
            this.interim = updatedInterim;
            this.$toast.open({
                position: "top-right",
                message: this.$t("synchronization.interim.success").toString(),
                type: "success",
                duration: 10000
            });
            this.loading = false;
            this.$forceUpdate();
        }
        catch (e) {
            this.loading = false;
            this.$toast.open({
                position: "top-right",
                message: this.$t("errors.Interim.synchronization._default").toString(),
                type: "error",
                duration: 10000
            });
        }
    }

    onChangeValidation(updatedInterim: IInterimDB) {
        this.interim = updatedInterim;
        this.tmpInterim = clonedeep(updatedInterim);
    }

    async update() {
        this.innerLoading = true;
        this.errors = {};
        this.globalErrors = [];
        try {
            const toSend:any = clonedeep(this.tmpInterim);

            // if (!isValidPhone(toSend.phone)) {
            //     this.errors.phone = {id: "Validation.Phone.Invalid"};
            // }
            //
            // if (!isValidEmail(toSend.email)) {
            //     this.errors.email = {id: "Validation.Email.Invalid"};
            // }
            //
            if (Object.keys(this.errors).length) {
                this.innerLoading = false;
                return;
            }

            if (toSend.birthDate) {
                if(this.mode === "edit") {
                    toSend.birthDate = moment(toSend.birthDate, this.$t('date.format')).valueOf();
                }
            }

            if (toSend.nir) {
                toSend.nir = toSend.nir.replaceAll(' ', '');
            }

            if (toSend.blacklist) {
                toSend.blacklist = toSend.blacklist.map((b:any) => ({from: b.from, companyId: b.companyId}));
            }

            if (toSend.jobs?.length) {
                toSend.jobs.forEach((job:any) => {
                    if (job.certifications) job.certifications = job.certifications.map(this.NaNOrValue);
                });
            }

            if (toSend.managers) {
                if ((!toSend.manager || toSend.manager === '') && toSend.managers[this.currentAgency._id]) {
                    delete toSend.managers[this.currentAgency._id];
                } else {
                    toSend.managers[this.currentAgency._id] = toSend.manager;
                }
            } else if (toSend.manager || toSend.manager !== '') {
                toSend.managers = {};
                toSend.managers[this.currentAgency._id] = toSend.manager;
            }
            delete toSend.manager;

            const result = await this.editInterim(toSend);
            this.interim = {
                ...result,
                jobs: result.jobs.map((job: any) => {
                    const j = this.getJob(job.jobId);
                    if (!j) return;

                    return { ...job, name: j.name || j.jobName }
                }).filter((a:any) => a)
            }

            if(result.warning) {
                this.$toast.open({
                    position: "top-right",
                    message: this.$t("errors.Interim." + result.warning.context + "." + result.warning.id).toString(),
                    type: "warning",
                    duration: 10000
                });
            }

            if (this.interim?.blacklist) {
                this.interim?.blacklist?.forEach((i:any) => {
                    let c = this.clients.find((cl:any) => cl._id === i.companyId);

                    if (c) {
                        i.companyName = c.name;
                    }

                });
            }
            this.quitEdition();
            this.$toast.open({
                position: "top-right",
                message: this.$t('page.interims.update').toString(),
                type: "success",
                duration: 10000
            })
        } catch (e) {
            this.errors = e.errors;
            this.globalErrors = e._global;
            this.$toast.open({
                position: "top-right",
                message: "Une erreur est survenue, veuillez verifier que les champs obligatoires sont bien remplis",
                type: "error",
                duration: 10000
            });
            console.error(this.errors);
        }
        this.innerLoading = false;
    }

    async init() {
        this.loading = true;
        this.globalErrors = [];
        try {
            this.interim = (await this.loadInterim({interimId: this.interimId, query: {missions: true}}));
            if(this.interim && !this.interim.administrativeDocuments) {
                // this.interim.administrativeDocuments = { habilitations : []}
            }

            const result:any = await this.loadCompanies({mode: 'list'});
            this.clients = result.rows;
            if (this.interim?.blacklist) {
                this.interim?.blacklist.forEach((i:any)=> {
                    let c = this.clients.find((cl:any) => cl._id === i.companyId);

                    if (c) {
                        i.companyName = c.name;
                    }
                });
            }
            if (this.interim?.stats) {
                this.stats = this.interim.stats;
            }
        } catch (e) {
            console.log(e);
            this.errors = e.errors;
            this.globalErrors = e._global;

            if (e?.fullErr?.response?.status === 404) {
                this.globalErrors.push({id: "Interim.NotFound"});
                await this.removeLink(this.interimId);
            }
            if (e?.fullErr?.response?.status === 500) {
                this.globalErrors.push({id: "Global.ServerError"});
                await this.removeLink(this.interimId);
            }
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
