
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import VModel from "@/mixins/VModel.vue";

    @Component({
        name: "Dropzone"
    })
    export default class Dropzone extends mixins(VModel) {
        @Prop() accept!: string[];

        $refs!: {
            input: HTMLInputElement
        }
        meta:any = {
            name: "",
            size: 0,
            type: ""
        };

        @Watch('value')
        onValue() {
            if (!this.value) {
                this.meta = {
                    name: "",
                    size: 0,
                    type: ""
                };
                this.$refs.input.value = "";
            }
        }

        get size() {
            let _size = this.meta.size,
                fSExt = ['Octets', 'Ko.', 'Mo.', 'Go.'],
                i=0;

            while(_size > 900)  {
                _size /= 1024;
                i++;
            }
            return (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
        }

        onFileChange(e:any) {
            const files:File[] = e.target.files || e.dataTransfer.files;
            if (this.accept && !this.accept.some(extension => files[0].name.includes(extension))) {
                // @ts-ignore;
                this.$toast.open({
                    message: this.$t('page.candidate.parsing.badExtension'),
                    type: 'error',
                    duration: 5000
                });
                return
            }

            if (files.length) {
                this.createFile(files[0]);
            }
        }

        createFile(file:any) {
            const reader: FileReader = new FileReader();

            reader.onload = (e:any) => {
                this.meta.name = file.name;
                this.meta.size = file.size;
                this.meta.type = file.type;
                this.innerValue = e.target.result;
                this.$emit('droped', e.target.result)
            };
            reader.readAsDataURL(file);
        }

        removeFile(e:any) {
            this.innerValue = '';
        }

        focusInput() {
            this.$refs.input.click();
        }
    }
